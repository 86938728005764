import type { ApiIndicatorScaleSchema } from '@/interfaces/Api.generated.interfaces'

type IndicatorScaleSchema = ApiIndicatorScaleSchema

export const scaleColorIndicator = (score: number, scale: IndicatorScaleSchema | null = null): string => {
  const defaultColor = '#eeeeee'

  if (!scale || scale.indicator_levels === void 0 || !scale.indicator_levels || score === null) {
    return defaultColor
  }

  for (let i = 0; i < scale.indicator_levels.length; i++) {
    const current = scale.indicator_levels[i].value

    if (i < scale.indicator_levels.length - 1) {
      const next = scale.indicator_levels[i + 1].value

      if (current !== void 0 && next !== void 0 && score >= current && score < next) {
        return scale.indicator_levels[i].color ?? defaultColor
      }
    } else {
      if (score >= 100 && scale && scale.type !== 'PRIORITY') {
        return scale.color ?? defaultColor
      } else if (current !== void 0 && score >= current && score <= 100) {
        return scale.indicator_levels[i].color ?? defaultColor
      }
    }
  }

  if (score >= 100) {
    return scale.color ?? defaultColor
  }

  return defaultColor
}
